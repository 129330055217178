/** @jsx jsx */
import React from "react";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import DateFormat from "@/components/date-format"
import { jsx } from '@emotion/react'

import { 
  latestBlogItem,
  lbiThumb,
  lbiDetails
 }  from '../assets/styles/BlogCard.styles'

const BlogCard = ({ data, url }) => {

  const exerptText = (texto, limite) => {
    if (texto?.length <= limite) {
      return texto; 
    }
    const textoLimitado = texto?.slice(0, limite);
    return textoLimitado + '...';
  }
  // const { image, title, url, date } = data;
  // const { id, attributes :{featured_image, slug, title, content, autor} } = data;
  // const { id, attributes :{featured_image, slug, title, content, autor} } = data;
  return (  
    <div css={latestBlogItem}>
      <div css={lbiThumb}>
      <Link to={`/blog/${data?.attributes?.slug}`}>
          {data?.attributes?.imagem_destaque && (
            <img src={url+data?.attributes?.imagem_destaque?.data?.attributes?.url} alt={data?.attributes?.imagem_destaque?.data?.attributes?.name} />
          )}
       </Link>
      </div>
      <div css={lbiDetails}>
      <span>
      <DateFormat createdDate={data?.attributes?.createdAt} />
      </span>
        <h2>
          <Link to={`/blog/${data?.attributes?.slug}`}>{data?.attributes?.titulo}</Link>
        </h2>
       <ReactMarkdown>{exerptText(data?.attributes?.resumo, 160)}</ReactMarkdown>
        <p>
          por: <span>{data?.attributes?.autor}</span>
        </p>
        <Link className="learnM" to={`/blog/${data?.attributes?.slug}`}>
          Ver mais
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
